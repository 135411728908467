const sign = [
	{label:'银行签约',value:1},
	{label:'意数签约',value:2},
]
const nper = [
	{label:'60期',value:'60'},
	{label:'48期',value:'48'},
	{label:'36期',value:'36'},
	{label:'24期',value:'24'},
	{label:'18期',value:'18'},
	{label:'12期',value:'12'},
	{label:'6期',value:'6'},
	// {label:'10期',value:'10'},
	// {label:'3期',value:'3'},
	// {label:'72期',value:'72'},
	// {label:'84期',value:'84'},
	// {label:'96期',value:'96'}
]
const region = [
	{label:'黄浦区',value:'黄浦区'},
	{label:'徐汇区',value:'徐汇区'},
	{label:'长宁区',value:'长宁区'},
	{label:'静安区',value:'静安区'},
	{label:'普陀区',value:'普陀区'},
	{label:'虹口区',value:'虹口区'},
	{label:'杨浦区',value:'杨浦区'},
	{label:'宝山区',value:'宝山区'},
	{label:'闵行区',value:'闵行区'},
	{label:'嘉定区',value:'嘉定区'},
	{label:'浦东新区',value:'浦东新区'},
	{label:'松江区',value:'松江区'},
	{label:'金山区',value:'金山区'},
	{label:'青浦区',value:'青浦区'},
	{label:'奉贤区',value:'奉贤区'},
	{label:'崇明区',value:'崇明区'}  	
]
const rate_type = [
	{label:'月手续费率',value:'月手续费率'},
	{label:'总手续费率',value:'总手续费率'},
	{label:'年化利率',value:'年化利率'}
]
const status = [
	{label:'签约中',value:2,disable:false},
	{label:'签约失败',value:28,disable:false},
	{label:'审批中',value:4,disable:false},
	{label:'审批通过',value:5,disable:false},
	{label:'拒绝',value:6,disable:false},
	{label:'已放款',value:8,disable:false},
	{label:'驳回',value:30,disable:false},
	{label:'放弃进件',value:31,disable:false}
	// {label:'支用完成',value:'10'}
]
const native = [
	{label:'上海',value:'上海'},
	{label:'其他地区',value:'其他地区'}
]
const marital = [
	{label:'已婚',value:'已婚'},
	{label:'未婚',value:'未婚'},
	{label:'分居',value:'分居'},
	{label:'离异',value:'离异'},
	{label:'丧偶',value:'丧偶'},
	{label:'其他',value:'其他'}
]
const edu = [
	{label:'初中及以下',value:'初中及以下'},
	{label:'高中/中专',value:'高中/中专'},
	{label:'大专',value:'大专'},
	{label:'本科',value:'本科'},
	{label:'研究生及以上',value:'研究生及以上'}
]
const job = [
	{label:'公务员',value:'公务员'},
	{label:'事业单位员工',value:'事业单位员工'},
	{label:'职员',value:'职员'},
	{label:'军人',value:'军人'},
	{label:'自由职业者',value:'自由职业者'},
	{label:'工人',value:'工人'},
	{label:'农民',value:'农民'},
	{label:'管理人员',value:'管理人员'},
	{label:'技术人员',value:'技术人员'},
	{label:'学生',value:'学生'},
	{label:'个体经营者',value:'个体经营者'}
]
const unit_type = [
	{label:'公务员',value:'公务员'},
	{label:'国有企业',value:'国有企业'},
	{label:'事业单位',value:'事业单位'},
	{label:'上市公司',value:'上市公司'},
	{label:'外资/合资企业',value:'外资/合资企业'},
	{label:'民营企业',value:'民营企业'},
	{label:'自由职业',value:'自由职业'},
	{label:'无业',value:'无业'}
]
const purpose = [
	{ label: '购车', value: '购车' },
	{ label: '装修', value: '装修' },
	{ label: '婚庆', value: '婚庆' },
	{ label: '车位', value: '车位' },
	// { label: '经营', value: '经营' },
	{ label: '摩托车', value: '摩托车' },
	{ label: '其他', value: '其他' },
]

const house = [
	{label:'有',value:'有'},
	{label:'无',value:'无'}
]
const house_type = [
	{label:'商品房',value:'商品房'},
	{label:'经适房',value:'经适房'},
	{label:'动迁房',value:'动迁房'},
	{label:'宅基地',value:'宅基地'},
	{label:'商住两用房',value:'商住两用房'},
	{label:'其他',value:'其他'}
]
const car_type = [
	{label:'燃油车',value:'燃油车'},
	{label:'新能源车',value:'新能源车'}
]
const car_genre = [
	{label:'私牌',value:'私牌'},
	{label:'公牌',value:'公牌'}
]
const debt = [
	{label:'有（请在备注中说明）',value:'有'},
	{label:'无',value:'无'}
]
//签约失败
const refuse = [
	/* {label:'资质不符',value:'资质不符'},
	{label:'征信问题',value:'征信问题'},
	{label:'未扫码',value:'未扫码'},
	{label:'预审未通过',value:'预审未通过'},
	{label:'预审通过后问题',value:'预审通过后问题'}, 
	{label:'未预审',value:'未预审'},
	{label:'客户取消',value:'客户取消'},
	{label:'客服不建议办理',value:'客服不建议办理'},
	{label:'其他',value:'其他'}*/
	{label:'资质不符',value:'资质不符'},
	{label:'客户取消',value:'客户取消'},
]
//拒绝
const bank_reject = [
	{label:'负债率高',value:'负债率高'},
	{label:'征信异常',value:'征信异常'},
	{label:'查询超标',value:'查询超标'},
	{label:'诉讼案件',value:'诉讼案件'},
	{label:'高风险客户',value:'高风险客户'},
	{label:'黑名单客群',value:'黑名单客群'},
	{label:'核访异常',value:'核访异常'},
	{label:'资质不符',value:'资质不符'},
	{label:'多头授信',value:'多头授信'},
	{label:'客户取消',value:'客户取消'},
	{label:'产品互斥',value:'产品互斥'},
	{label:'白户客户',value:'白户客户'},
	{label:'经营异常',value:'经营异常'},
	{label:'综合评分不足',value:'综合评分不足'},
	{label:'其他',value:'其他'}
]

//消费类型
const consume = [
	{label:'信用卡',value:'信用卡'},
	{label:'借记卡',value:'借记卡'},
	{label:'现金',value:'现金'},
	{label:'信用卡+现金',value:'信用卡+现金'}
]

// 凭证类型
const consume_type = [
	{label:'POS单',value:'POS单'},
	{label:'小票',value:'小票'},
	{label:'发票',value:'发票'},
	{label:'付款截图',value:'付款截图'},
	{label:'合同',value:'合同'},
	{label:'行方陪同消费',value:'行方陪同消费'},
	{label:'无凭证',value:'无凭证'}
]

const cert = [
	{label: '有打卡工资+社保',value: '有打卡工资+社保'},
	{label: '有公积金',value: '有公积金'},
	{label: '有上海房产',value: '有上海房产'},
	{label: '有大额银行资产',value: '有大额银行资产'},
	{label: '本科及以上学历',value: '本科及以上学历'},
	{label: '名下有公司',value: '名下有公司'},
]
const nperColumns = [
  [
    { label: '60期', value: 60 },
    { label: '48期', value: 48 },
    { label: '36期', value: 36 },
    { label: '24期', value: 24 },
    { label: '12期', value: 12 },
  ]
]
// 贷款期限
const loans_month = [
	{label:'1年 (12期)',value:12},
	{label:'2年 (24期)',value:24},
	{label:'3年 (36期)',value:36},
	{label:'4年 (48期)',value:48},
	{label:'5年 (60期)',value:60},
	{label:'6年 (72期)',value:72},
	{label:'7年 (84期)',value:84},
	{label:'8年 (96期)',value:96},
	{label:'9年 (108期)',value:108},
	{label:'10年 (120期)',value:120},
	{label:'11年 (132期)',value:132},
	{label:'12年 (144期)',value:144},
	{label:'13年 (156期)',value:156},
	{label:'14年 (168期)',value:168},
	{label:'15年 (180期)',value:180},
	{label:'16年 (192期)',value:192},
	{label:'17年 (204期)',value:204},
	{label:'18年 (216期)',value:216},
	{label:'19年 (228期)',value:228},
	{label:'20年 (240期)',value:240},
	{label:'21年 (252期)',value:252},
	{label:'22年 (264期)',value:264},
	{label:'23年 (276期)',value:276},
	{label:'24年 (288期)',value:288},
	{label:'25年 (300期)',value:300},
	{label:'26年 (312期)',value:312},
	{label:'27年 (324期)',value:324},
	{label:'28年 (336期)',value:336},
	{label:'29年 (348期)',value:348},
	{label:'30年 (360期)',value:360},
]
const loans_status = [
	{label:'签约中',value:1},
	{label:'签约失败',value:2},
	{label:'审批中',value:3},
	{label:'补件',value:4},
	{label:'审批通过',value:5},
	{label:'拒绝',value:6},
	{label:'抵押中',value:7},
	{label:'已放款',value:8}
]
export {
	sign,
	nper,
	region,
	rate_type,
	status,
	native,
	marital,
	edu,
	job,
	unit_type,
	purpose,
	house,
	house_type,
	car_type,
	car_genre,
	debt,
	refuse,
	bank_reject,
	consume,
	consume_type,
	cert,
	nperColumns,
	loans_month,
	loans_status
}