import { lazy } from "react"
import { Navigate } from "react-router-dom"
import Login from "../pages/Login"
import Index from '../pages/Index'
import Business from "../pages/Business"
import Sign from '../pages/Sign'
import Exam from '../pages/Exam'
import Team from '../pages/Team'
import Outside from '../pages/Outside'
import Yishubao from '../pages/Yishubao'
import YishubaoHome from '../pages/Yishubao/Home'
import Activity from "../pages/Activity"
import Calculator from "../pages/Calculator"
import FAQ from "../pages/FAQ"
//import YishubaoExchange from '../pages/Yishubao/Exchange'

const BusinessAdd = lazy(()=>import('../pages/Business/Add'))
const BusinessList = lazy(()=>import('../pages/Business/List'))
const BusinessDetail = lazy(()=>import('../pages/Business/Detail'))
const BusinessReject = lazy(()=>import('../pages/Business/Reject'))
const BusinessService = lazy(()=>import('../pages/Business/Service'))
const BusinessServiceDetail = lazy(()=>import('../pages/Business/Service/Detail'))
const BusinessMoney = lazy(()=>import('../pages/Business/Money'))
const BusinessUpload = lazy(()=>import('../pages/Business/Upload'))
const BusinessUploadEdit = lazy(()=>import('../pages/Business/UploadEdit'))
const BusinessAppeal = lazy(()=>import('../pages/Business/Appeal'))
const BusinessProof = lazy(()=>import('../pages/Business/Proof'))
const BusinessPending = lazy(()=>import('../pages/Business/Pending'))
const PendingDetail = lazy(()=>import('../pages/Business/Pending/Detail'))
const PendingAdd = lazy(()=>import('../pages/Business/Pending/Add'))
const BusinessHouseAdd = lazy(()=>import('../pages/Business/House/Add'))
const BusinessHouseYsb = lazy(()=>import('../pages/Business/House/Ysb'))
const BusinessHouseList = lazy(()=>import('../pages/Business/House/List'))
const BusinessHouseDetail = lazy(()=>import('../pages/Business/House/Detail'))
const BusinessHouseUpload = lazy(()=>import('../pages/Business/House/Upload'))
const BusinessYsb = lazy(()=>import('../pages/Business/Ysb/index'))
const BusinessYsbList = lazy(()=>import('../pages/Business/Ysb/List'))
const BusinessYsbDetail = lazy(()=>import('../pages/Business/Ysb/detail'))
const BusinessStat = lazy(()=>import('../pages/Business/Stat'))

const SignList = lazy(()=>import('../pages/Sign/List'))
const SignReject = lazy(()=>import('../pages/Sign/Reject'))
const SignUpdate = lazy(()=>import('../pages/Sign/Update'))
const SignHouse = lazy(()=>import('../pages/Sign/House'))

const ExamCenter = lazy(()=>import('../pages/Exam/Center'))
const ExamDetail = lazy(()=>import('../pages/Exam/Detail'))
const ExamList = lazy(()=>import('../pages/Exam/List'))
const ExamPaper = lazy(()=>import('../pages/Exam/Paper'))
const TeamList = lazy(()=>import('../pages/Team/List'))
const TeamAppeal = lazy(()=>import('../pages/Team/Appeal'))
const TeamYsb = lazy(()=>import('../pages/Team/Ysb'))
const TeamHouse = lazy(()=>import('../pages/Team/House'))

const OutsideRegister = lazy(()=>import('../pages/Outside/Register'))
//const OutsideIndex = lazy(()=>import('../pages/Outside/Index'))
const OutsideAdd = lazy(()=>import('../pages/Outside/Add'))
const OutsideList = lazy(()=>import('../pages/Outside/List'))
const OutsideIndex = lazy(()=>import('../pages/Outside/Index/index.jsx'))
const OutsideDetail = lazy(()=>import('../pages/Outside/Detail'))
const OutsidePartner = lazy(()=>import('../pages/Outside/Partner'))
const OutsideTeam = lazy(()=>import('../pages/Outside/Team'))
const OutsideProduct = lazy(()=>import('../pages/Outside/Product'))
const OutsideAccount = lazy(()=>import('../pages/Outside/Account'))
const OutsideChannel = lazy(()=>import('../pages/Outside/Channel'))
const OutsideWork = lazy(()=>import('../pages/Outside/Work'))
const OutsideLogin = lazy(()=>import('../pages/Outside/Login'))

const YishubaoIndex = lazy(()=>import('../pages/Yishubao/Index/index.jsx'))
const YishubaoMine = lazy(()=>import('../pages/Yishubao/Mine/index.jsx'))
const YishubaoExchange = lazy(()=>import('../pages/Yishubao/Exchange/index.jsx'))

const ActivityCar = lazy(()=>import('../pages/Activity/Car'))
const ActivitySuccess = lazy(()=>import('../pages/Activity/Success'))

const FaqIndex = lazy(()=>import('../pages/FAQ/Index/index.jsx'))
const FaqLogin = lazy(()=>import('../pages/FAQ/Login'))
const FagList = (lazy(()=>import('../pages/FAQ/List')))
const FagDetail = (lazy(()=>import('../pages/FAQ/Detail')))

const routes = [
  {
    path:'/',
    element:<Login/>
  },
  {
    path:'/login',
    element:<Login/>
  },
  {
    path:'/index',
    element:<Index/>
  },
  {
    path:'/business',
    element:<Business/>,
    children:[
      {
        path:'add',
        element:<BusinessAdd/>
      },
      {
        path:'list',
        element:<BusinessList/>
      },
      {
        path:'detail/:id',
        element:<BusinessDetail/>
      },
      {
        path:'reject',
        element:<BusinessReject/>
      },
      {
        path:'service',
        element:<BusinessService/>,
      },
      {
        path:'service_detail/:id',
        element:<BusinessServiceDetail/>,
      },
      {
        path:'money',
        element:<BusinessMoney/>
      },
      {
        path:'upload',
        element:<BusinessUpload/>
      },
      {
        path:'edit',
        element:<BusinessUploadEdit/>
      },
      {
        path:'appeal',
        element:<BusinessAppeal/>
      },
      {
        path:'proof',
        element:<BusinessProof/>,
      },
      {
        path:'pending',
        element:<BusinessPending/>,
      },
      {
        path:'pendingDetail/:id',
        element:<PendingDetail/>,
      },
      {
        path:'pendingAdd/:id',
        element:<PendingAdd/>,
      },
      {
        path:'house',
        element:<BusinessHouseList/>,
      },
      {
        path:'houseYsb/:id',
        element:<BusinessHouseYsb/>,
      },
      {
        path:'houseAdd',
        element:<BusinessHouseAdd/>,
      },
      {
        path:'houseDetail/:father/:id',
        element:<BusinessHouseDetail/>,
      },
      {
        path:'houseUpload/:id/:house_type',
        element:<BusinessHouseUpload/>,
      },
      {
        path:'ysb',
        element:<BusinessYsb/>,
      },
      {
        path:'ysblist',
        element:<BusinessYsbList/>,
      },
      {
        path:'ysbdetail/:userid',
        element:<BusinessYsbDetail/>,
      },
      {
        path:'stat',
        element:<BusinessStat/>
      },
    ]
  },
  {
    path:'/team',
    element:<Team/>,
    children:[
      {
        path:'list',
        element:<TeamList/>
      },
      {
        path:'appeal',
        element:<TeamAppeal/>
      },
      {
        path:'ysb',
        element:<TeamYsb/>
      },
      {
        path:'house',
        element:<TeamHouse/>
      }
    ]
  },
  {
    path:'/exam',
    element:<Exam/>,
    children:[
      {
        path:'center',
        element:<ExamCenter/>
      },
      {
        path:'detail/:id',
        element:<ExamDetail/>
      },
      {
        path:'list',
        element:<ExamList/>
      },
      {
        path:'paper/:id',
        element:<ExamPaper/>
      },
    ]
  },
  {
    path:'/sign',
    element:<Sign/>,
    children:[
      {
        path:'list/:userid/:flag',
        element:<SignList/>
      },
      {
        path:'reject',
        element:<SignReject/>
      },
      {
        path:'update/:id',
        element:<SignUpdate/>
      },
      {
        path:'house',
        element:<SignHouse/>
      }
    ]
  },
  {
    path:'/outside',
    element:<Outside/>,
    children:[
      {
        path:'',
        element:<Navigate to="index"/>
      },
      {
        path:'index/:openid',
        element:<OutsideIndex/>
      },
      {
        path:'register',
        element:<OutsideRegister/>
      },
      {
        path:'add',
        element:<OutsideAdd/>
      },
      {
        path:'list',
        element:<OutsideList/>
      },
      {
        path:'detail/:id',
        element:<OutsideDetail/>
      },
      {
        path:'partner',
        element:<OutsidePartner/>
      },
      {
        path:'team',
        element:<OutsideTeam/>
      },
      {
        path:'product',
        element:<OutsideProduct/>
      },
      {
        path:'account',
        element:<OutsideAccount/>
      },
      {
        path:'channel',
        element:<OutsideChannel/>
      },
      {
        path:'work',
        element:<OutsideWork/>
      },
      {
        path:'login',
        element:<OutsideLogin/>
      }
    ]
  },
  {
    path:'/faq',
    element:<FAQ/>,
    children:[
      {
        path:'',
        element:<Navigate to="login"/>
      },
      {
        path:'login',
        element:<FaqLogin/>
      },
      {
        path:'index',
        element:<FaqIndex/>
      },
      {
        path:'list',
        element:<FagList/>
      },
      {
        path:'detail/:id',
        element:<FagDetail/>
      }
    ]
  },
  {
    path:'/ysb',
    element:<Yishubao/>,
    children:[
      {
        path:'',
        element:<Navigate to="home"/>
      },
      // {
      //   path:'index',
      //   element:<YishubaoIndex />
      // },
      // {
      //   path:'mine',
      //   element:<YishubaoMine />
      // },
      {
        path:'home',
        element:<YishubaoHome/>,
        children:[
          {
            path:'',
            element:<Navigate to="index"/>
          },
          {
            path:'index',
            element:<YishubaoIndex />
          },
          {
            path:'mine',
            element:<YishubaoMine />
          },
        ]
      },
      {
        path:'exchange',
        element:<YishubaoExchange/>
      },
    ]
  },
  {
    path:'/activity',
    element:<Activity/>,
    children:[
      {
        path:'',
        element:<Navigate to="car"/>
      },
      {
        path:'car/:activity_id',
        element:<ActivityCar/>
      },
      {
        path:'success',
        element:<ActivitySuccess/>
      }
    ]
  },
  {
    path:'/calculator',
    element:<Calculator/>
  }
] 

export default routes